<template>
  <div class="h-screen">
    <!-- Login actions -->
    <div v-if="!form.successful" class="flex-col-center justify-center">
      <div class="w-full my-5 md:my-28">
        <div class="absolute top-5 right-5">
          <SelectLangs />
        </div>

        <div class="w-full mx-auto sm:max-w-md mb-7">
          <Brand size="normal" />
        </div>

        <!-- Form -->
        <div class="w-full mx-auto sm:max-w-md px-5 sm:p-0">
          <Card>
            <template #title>
              <div class="mx-6 pt-4">
                <!-- Form title -->
                <h2 class="text-xl font-semibold">{{ $t("login_title") }}</h2>
              </div>
            </template>
            <template #content>
              <div class="flex flex-col w-full">
                <form @submit.prevent="submit" @keydown="form.onKeydown($event)">
                  <!-- Email field -->
                  <div class="form-group">
                    <label for="email" class="form-label">{{ $t("email") }} <LabelRequired /></label>
                    <InputText v-model="form.email" id="email" name="email" type="email" class="p-inputtext-sm" autofocus />
                    <HasError :form="form" field="email" />
                  </div>

                  <!-- Password field -->
                  <div class="form-group">
                    <label for="password" class="form-label">{{ $t("password") }} <LabelRequired /></label>
                    <InputText v-model="form.password" id="password" name="password" type="password" class="p-inputtext-sm" />
                    <HasError :form="form" field="password" />
                  </div>

                  <!-- Form submit button -->
                  <Button type="submit" :label="$t('login_button')" :loading="form.busy" iconPos="right" class="p-button-primary p-button-lg shadow w-full" />

                  <!-- Form error general -->
                  <HasError :form="form" field="error" />
                </form>

                <Divider align="center">
                  <span>{{ $t("or_continue") }}</span>
                </Divider>

                <!-- Social login buttons -->
                <SocialLogin />

                <!-- Auxiliar actions -->
                <div class="mt-2">
                  <Divider />
                  <div class="flex-center justify-between">
                    <router-link :to="{ name: 'Reset' }">
                      <Button :label="$t('forgot')" icon="pi pi-unlock" iconPos="left" class="p-button-secondary p-button-text" />
                    </router-link>
                    <a href="https://help.setup.baymark.net/es/docs" target="_blank">
                      <Button :label="$t('help')" icon="pi pi-info-circle" iconPos="left" class="p-button-secondary p-button-text" />
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <!-- Don't have an account!, please go to register -->
        <div class="flex-col-center mt-3">
          <router-link :to="{ name: 'Register' }">
            <Button :label="$t('dont_have_an_account')" class="p-button-secondary p-button-text" />
          </router-link>
        </div>
      </div>
    </div>

    <!-- Login success -->
    <div v-else class="flex-col-center justify-center h-full">
      <Loader :onlyLoader="false" title="" :text="$t('loader.login')" />
    </div>
  </div>
</template>

<script>
// Framework components
import Card from "primevue/card"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Divider from "primevue/divider"

import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import { HasError } from "vform/src/components/tailwind"

// App components
import Brand from "@/components/Brand"
import SocialLogin from "@/components/SocialLogin"
import SelectLangs from "@/components/SelectLangs"
import Loader from "@/components/UI/Loader.vue"
import LabelRequired from "@/components/LabelRequired"

export default {
  name: "Login",
  components: {
    // Framework components
    Card,
    Button,
    InputText,
    Divider,
    HasError,

    // App components
    Brand,
    SocialLogin,
    SelectLangs,
    Loader,
    LabelRequired
  },

  data() {
    return {
      token: this.$route.query?.access_token || localStorage.getItem("Token"),
      hasInvitations: this.$route.query?.project_id,
      form: new FormClient({
        email: "",
        password: ""
      })
    }
  },

  mounted() {
    if (this.token && this.token != "null") {
      this.form.successful = true
      this.login(this.token)
    }
  },

  methods: {
    async submit() {
      const response = await this.form.post(ApiRoute.auth.login)
      this.login(response.data.access_token)
    },

    async login(token) {
      try {
        await this.$store.dispatch("Auth/setToken", token)
        const response = await new FormClient().get(ApiRoute.auth.profile)
        await this.$store.dispatch("Auth/setUser", response.data)
        this.$router.push({ name: "Onboarding redirect", query: { hasInvitations: this.hasInvitations } })
      } catch (exception) {
        if (exception?.status == 401) {
          localStorage.removeItem("Token")
          location.reload()
        }
      }
    }
  }
}
</script>
